<template>
  <div class="container-fluid pt-0 row mx-0 bg-primary">
    <div class="card mb-3">
      <img src="" class="card-img-top" alt="" />
      <div class="card-body">
        <h5 class="card-title">{{ news.title }}</h5>

        <div class="card-text overflow-hidden" v-html="news.content"></div>
        <p class="card-text fs-6 fw-lighter">
          发布于
          <span class="text-muted">{{ news.publish }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
export default {
  name: "newsdetail",
  data: function () {
    return {
      news: {},
    };
  },
  computed: {},
  mounted() {
    axios
      .get(this.$backend_url + "thenews/" + this.$route.params.id)
      .then((res) => {
        let mynewsdata = res.data[0];
        let time = new Date(mynewsdata.fields.publish);
        this.news = {
          id: mynewsdata.pk,
          title: mynewsdata.fields.title,
          author: mynewsdata.fields.author,
          content: mynewsdata.fields.content,
          publish: time.toLocaleString("zh-CN"),
        };
      })
      .catch(() => {});
  },
};
</script>
 <style>
</style>
