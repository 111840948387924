<template>
  <div>
    <div class="container-fluid pt-0 row mx-0 bg-primary">
      <truckage-list />
    </div>
  </div>
</template>

<script>
import truckageList from "../components/truckageList.vue";
// @ is an alias to /src
export default {
  components: { truckageList },
  data: function () {
    return {
      msg: String,
    };
  },
  name: "ShowTruckage",
};
</script>
 <style>
</style>
