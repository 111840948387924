<template>
  <div class="container-fluid card">
    <h3 class="my-4">省市运费查询</h3>

    <div class="d-flex">
      <input
        class="form-control me-2"
        type="search"
        placeholder="请输入目标城市："
        aria-label="Search"
        v-model="search"
        v-on:input="searching"
      />
      <button
        class="btn btn-outline-success col-3"
        type="buttun"
        v-on:click="searching"
      >
        开始搜索……
      </button>
    </div>
    <h4 class="my-4">{{ msg }}</h4>
    <table class="table table-striped table-hover" v-show="search !== ''">
      <thead>
        <tr>
          <th scope="col">始发地</th>
          <th scope="col">目的地</th>
          <th scope="col">当前运费（元/吨）</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in forSearch" :key="item.id">
          <th scope="row">{{ item.cityA }}</th>
          <td>{{ item.cityB }}</td>
          <td>{{ item.price }}</td>
        </tr>
      </tbody>
    </table>
    <hr class="my-4" />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col">始发地</th>
          <th scope="col">目的地</th>
          <th scope="col">当前运费（元/吨）</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in mylist" :key="item.id">
          <th scope="row">{{ item.cityA }}</th>
          <td>{{ item.cityB }}</td>
          <td>{{ item.price }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
export default {
  name: "truckagelist",
  data: function () {
    return {
      mylist: [],
      forSearch: [],
      found: false,
      search: "",
      msg: "请输入您要查询的城市名",
    };
  },
  methods: {
    searching: function () {
      // 恢复默认设置
      this.found = false;
      this.forSearch = [];
      //   数据为空时
      if (this.search === "") {
        this.msg = "搜索内容不能为空。";
      }
      //   数据不为空时
      else {
        for (let item of this.mylist) {
          if (item.cityA === this.search || item.cityB === this.search) {
            this.forSearch.push(item);
            this.msg = "含有" + this.search + "的数据如下：";
            this.found = true;
          }
        }
        if (this.found == false)
          this.msg =
            "抱歉：您输入的数据暂未录入系统数据库，或者您输入的城市名不正确。";
      }
    },
  },
  mounted() {
    axios
      .get(this.$backend_url + "cartagelist/")
      .then((res) => {
        this.mylist = res.data.map((mydata) => {
          return {
            id: mydata.pk,
            cityA: mydata.fields.cityA,
            cityB: mydata.fields.cityB,
            price: mydata.fields.price,
          };
        });
      })
      .catch(() => {});
  },
};
</script>
 <style>
</style>
