<template>
  <div class="container-fluid pt-0 row mx-0 bg-primary">
    <div class="card mb-3" v-for="item in newslist" :key="item.id">
      <img src="" class="card-img-top" alt="" />
      <div class="card-body">
        <router-link
          :to="{ name: 'newsdetail', params: { id: item.id } }"
          class="text-info"
        >
          <h5 class="card-title text-primary">{{ item.title }}</h5></router-link
        >

        <!-- <div class="card-text overflow-hidden" v-html="item.content"></div> -->
        <p class="card-text fs-6 fw-lighter">
          发布于
          <span class="text-muted">{{ item.publish }}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
export default {
  name: "ournews",
  data: function () {
    return {
      newslist: [],
    };
  },
  computed: {},
  mounted() {
    axios
      .get(this.$backend_url + "ournews/")
      .then((res) => {
        if (!res.data || res.data.length == 0) {
          this.$router.push("/news");
        } else {
          this.newslist = res.data.map((mydata) => {
            let time = new Date(mydata.fields.publish);
            return {
              id: mydata.pk,
              title: mydata.fields.title,
              author: mydata.fields.author,
              content: mydata.fields.content,
              publish: time.toLocaleString("zh-CN"),
            };
          });
        }
      })
      .catch(() => {
        this.$router.push("/news");
      });
  },
};
</script>
 <style>
</style>
